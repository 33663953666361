.navbar__banner {
  position: relative;
  min-height: calc(100vh - 8rem);
  overflow: hidden;
  background: radial-gradient(circle at 80% 30%, #3a4bb7 0%, var(--color-primary) 70%, var(--color-primary) 100%);
  /* background-image: url("../../Recursos/fondo.png");
  background-repeat: no-repeat; */
  background-size: cover;
  z-index: 0;
}

.banner__content {
  position: relative;
  display: inline-block;
  width: 60%;
  margin-left: 5rem;
  margin-bottom: 2rem;
  z-index: 2;
}

.banner__title {
  font-size: 3vw;
  font-weight: bold;
  line-height: 1.2;
  color: var(--color-text);
  margin-bottom: 2rem;
}

.banner__subtitle {
  font-size: 2vw;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-subtitle);
  margin-bottom: 3rem;
}

.banner__pattern {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 73vw;
  pointer-events: none;
  z-index: 2;
}

/* IMAGENES */
.banner__images {
  z-index: 3;
}

.image__item {
  position: absolute;
  z-index: 4;
  pointer-events: none;
}

.image__item--1 {
  bottom: 0;
  right: 3.5vw;
  /*text-align: right;*/

  width: 26vw;

  animation-name: showBottom;
  animation-duration: 1.3s;
  animation-timing-function: ease-in-out, ease-in-out;
}

.image__item--2 {
  bottom: 1vh;
  right: 1vw;
  width: 6.3vw;

  animation-name: showBottom;
  animation-duration: 1.3s;
  animation-timing-function: ease-in-out, ease-in-out;
}

.image__item--spilled {
  animation: rotateCupOfCoffee 2s ease-in-out forwards;
}

.image__item--3 {
  bottom: 1vh;
  right: 37vw;
  width: 10vw;

  animation-name: showBottom, vibrate;
  animation-duration: 1.3s, 1.3s;
  animation-delay: 0s, 1s;
  animation-iteration-count: 1, 1;
  animation-timing-function: ease-in-out, ease-in-out;
}

.image__item--4 .image,
.image__item--5 .image,
.image__item--6 .image,
.image__item--7 .image,
.image__item--8 .image,
.image__item--9 .image,
.image__item--10 .image,
.image__item--11 .image {
  width: 3.3vw;
  animation-name: showBottomIcons;
  animation-duration: 1s;
}

.image__item--4 {
  bottom: 11vw;
  left: 38vw;

  animation: rotateToLeft;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.image__item--5 {
  bottom: 16vw;
  left: 46vw;

  animation: rotateToRight;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.image__item--6 {
  bottom: 6vw;
  left: 45vw;

  animation: rotateToLeft;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.image__item--7 {
  bottom: 22vw;
  right: 42vw;

  animation: rotateToRight;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.image__item--8 {
  bottom: 20vw;
  right: 28vw;

  animation: rotateToLeft;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.image__item--9 {
  bottom: 14vw;
  right: 25vw;

  animation: rotateToRight;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.image__item--10 {
  bottom: 32vw;
  right: 6vw;

  animation: rotateToLeft;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.image__item--11 {
  bottom: 27vw;
  right: 2vw;

  animation: rotateToRight;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

@keyframes showBottom {
  0% {
    transform: translateY(105%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes showBottomIcons {
  0% {
    transform: translateY(800%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes vibrate {
  0% {
    transform: translateX(-2px) rotate(-1deg);
  }
  25% {
    transform: translateX(2px) rotate(1deg);
  }
  50% {
    transform: translateX(-2px) rotate(-1deg);
  }
  75% {
    transform: translateX(2px) rotate(1deg);
  }
  100% {
    transform: translateX(-2px) rotate(-1deg);
  }
}

@keyframes rotateToLeft {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes rotateToRight {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotateCupOfCoffee {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
